import React from 'react';
import { ThemeProvider } from './contexts/ThemeContext';
import RegisterSteps from "./components/pages/registerSteps";
import './App.css';
import './styles/global.css';

function App() {
  return (
      
    <ThemeProvider>
      <RegisterSteps />
    </ThemeProvider>
  );
}

export default App;
