import React, { useEffect, useState } from 'react';
import { Button } from './button';
import { useTheme } from '../../../contexts/ThemeContext';
import { MoonIcon, SunIcon, DesktopIcon, ChevronDownIcon } from '@radix-ui/react-icons';

interface DarkModeToggleProps {
  className?: string;
}

const DarkModeToggle: React.FC<DarkModeToggleProps> = ({ className }) => {
  const { isDarkMode, setDarkMode, mode, setMode } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      if (localStorage.getItem('mode') === 'system') {
        setDarkMode(mediaQuery.matches);
      }
    };

    handleChange(); // Set initial value
    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [setDarkMode]);

  const handleModeChange = (newMode: string) => {
    setMode(newMode);
    if (newMode === 'system') {
      const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setDarkMode(isDark);
    } else {
      setDarkMode(newMode === 'dark');
    }
    setIsOpen(false);
  };

  const getCurrentIcon = () => {
    if (mode === 'system') {
      return <DesktopIcon className="h-[1.2rem] w-[1.2rem]" />;
    }
    return isDarkMode ? <MoonIcon className="h-[1.2rem] w-[1.2rem]" /> : <SunIcon className="h-[1.2rem] w-[1.2rem]" />;
  };

  const availableModes = [
    { mode: 'light', icon: <SunIcon className="h-[1.2rem] w-[1.2rem]" /> },
    { mode: 'dark', icon: <MoonIcon className="h-[1.2rem] w-[1.2rem]" /> },
    { mode: 'system', icon: <DesktopIcon className="h-[1.2rem] w-[1.2rem]" /> },
  ].filter(m => m.mode !== mode);

  return (
    <div className={className}>
      <div className="relative">
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-between w-20 gap-2"
        >
          <span className="flex items-center gap-2">
            {getCurrentIcon()}
          </span>
          <ChevronDownIcon className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} />
        </Button>
        <div className={`absolute right-0 mt-2 w-20 bg-background dark:bg-gray-800 rounded-md shadow-lg overflow-hidden transition-all duration-300 ease-in-out origin-top ${isOpen ? 'max-h-40 scale-y-100' : 'max-h-0 scale-y-0'}`}>
          {availableModes.map(({ mode, icon }) => (
            <button
              key={mode}
              className="w-full text-left px-4 py-2 hover:bg-accent hover:text-accent-foreground dark:hover:bg-gray-700 flex items-center gap-2"
              onClick={() => handleModeChange(mode)}
            >
              {icon}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DarkModeToggle;