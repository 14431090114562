import React from 'react';
import { Button } from './button';

interface InfoIconProps {
    onClick: () => void;
}

const InfoIcon: React.FC<InfoIconProps> = ({ onClick }) => {
    return (
        <Button
            variant="outline"
            size="sm"
            aria-label="Tutorial"
            onClick={onClick}
            className="flex items-center justify-center w-20">
            Tutorial
        </Button>
    );
};

export default InfoIcon;