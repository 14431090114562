import React, { useEffect, useState } from 'react'
import { Button } from "../common/ui/button"
import { Input } from "../common/ui/input"
import { Label } from "../common/ui/label"
import { RadioGroup, RadioGroupItem } from "../common/ui/radioGroup"
import { Checkbox } from "../common/ui/checkbox"
import LanguageSwitcher from "../common/ui/languageSwitcher"
import { motion, AnimatePresence } from 'framer-motion';
import DarkModeToggle from "../common/ui/DarkModeToggle";
import InfoIcon from '../common/ui/InfoIcon'
import TutorialOverlay from '../overlays/tutorialOverlay';
import Alert from '../common/ui/Alert';
import { ResponsiveLine } from '@nivo/line';

interface FormData {
    name: string;
    age: string;
    weight: string;
    height: string;
    goal: string;
    trainingDays: string;
    trainingAreas: string[];

}

function detectUserLanguage() {
    const lang = navigator.language.split('-')[0];
    if (lang === 'en' || lang === 'de') {
        return lang;
    } else {
        return 'en'; // Default to English
    }
}

const dummyData = [
  {
    id: "weight",
    data: [
      { x: '01', y: 80 },
      { x: '02', y: 79 },
      { x: '03', y: 78 },
      { x: '04', y: 77 },
      { x: '05', y: 76 },
      { x: '06', y: 75 },
      { x: '07', y: 74 },
      { x: '08', y: 73 },
      { x: '09', y: 72 },
      { x: '10', y: 71 },
      { x: '11', y: 70 },
      { x: '12', y: 69 },
    ]
  }
];

export default function RegisterSteps() {
    const [currentStep, setCurrentStep] = useState(1)
    const [language, setLanguage] = useState(detectUserLanguage());
    const [translations, setTranslations] = useState<Record<string, string>>({})
    const [showTutorial, setShowTutorial] = useState(false);
    const [alertInfo, setAlertInfo] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);
    const [formData, setFormData] = useState<FormData>({
        name: '',
        age: '',
        weight: '',
        height: '',
        goal: '',
        trainingDays: '',
        trainingAreas: [],
    })

    useEffect(() => {
        const loadTranslations = async () => {
            try {
                const translationFile = await import(`@/localization/${language}.json`);
                setTranslations(translationFile.default);
            } catch (error) {
                console.error(`Translation file for ${language} not found. Falling back to default language.`);
                const defaultLang = 'en'; // or 'de', depending on your default
                const defaultTranslationFile = await import(`@/localization/${defaultLang}.json`);
                setTranslations(defaultTranslationFile.default);
                setLanguage(defaultLang);
            }
        };
        loadTranslations();
    }, [language]);

    const t = (key: string) => {
        return translations[key] || key;
    }

    const steps = [
        { number: 1, title: t('personal_info') },
        { number: 2, title: t('goals') },
        { number: 3, title: t('gym_plan') },
        { number: 4, title: t('overview') },
        { number: 5, title: t('purchase_process') },
    ]

    const showAlert = (message: string, type: 'success' | 'error' | 'warning' = 'error') => {
        setAlertInfo({ message, type });
    };

    const handleCloseAlert = () => {
        setAlertInfo(null);
    };

    const handleLanguageChange = (newLanguage: string) => {
        setLanguage(newLanguage)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        let adjustedValue = value

        if (name === 'age' && parseInt(value) < 18) {
            adjustedValue = '18'
            showAlert(t('age_error'), 'warning')
        } else if (name === 'age' && parseInt(value) > 100) {
            adjustedValue = '100'
            showAlert(t('age_error'), 'warning')
        } else if (name === 'weight' && parseInt(value) < 30) {
            adjustedValue = '30'
            showAlert(t('weight_error'), 'warning')
        } else if (name === 'weight' && parseInt(value) > 400) {
            adjustedValue = '400'
            showAlert(t('weight_error'), 'warning')
        } else if (name === 'height' && parseInt(value) < 100) {
            adjustedValue = '100'
            showAlert(t('height_error'), 'warning')
        } else if (name === 'height' && parseInt(value) > 250) {
            adjustedValue = '250'
            showAlert(t('height_error'), 'warning')
        }

        setFormData(prev => ({ ...prev, [name]: adjustedValue }))
    }

    const handleCheckboxChange = (area: string) => {
        setFormData(prev => ({
            ...prev,
            trainingAreas: prev.trainingAreas.includes(area)
                ? prev.trainingAreas.filter(a => a !== area)
                : [...prev.trainingAreas, area]
        }))
    }

    const renderStepContent = (step: number) => {
        return (
            <div className="space-y-4 flex flex-col">
                {renderStepFields(step)}
            </div>
        )
    }

    const isStepValid = (step: number) => {
        if (process.env.REACT_APP_DEV_MODE === 'true') {
            return true; // Always return true in development mode
        }

        switch (step) {
            case 1:
                return formData.name && formData.age && formData.weight && formData.height;
            case 2:
                return formData.goal !== '';
            case 3:
                if (formData.trainingDays === '0') {
                    return true; // "No workout" is selected
                } else {
                    return formData.trainingDays !== '' &&
                        parseInt(formData.trainingDays) >= 1 &&
                        parseInt(formData.trainingDays) <= 7 &&
                        formData.trainingAreas.length > 0;
                }
            case 4:
                return true; // Overview step, always valid
            case 5:
                return true; // Assuming the purchase process is always valid
            default:
                return false;
        }
    };

    const renderStepFields = (step: number) => {
        switch (step) {
            case 1:
                return (
                    <>
                        <div className="space-y-2">
                            <Label htmlFor="name">{t('name')}</Label>
                            <Input id="name" name="name" value={formData.name} onChange={handleInputChange} placeholder={t('your_name')} />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="age">{t('age')}</Label>
                            <Input id="age" name="age" type="number" value={formData.age} onChange={handleInputChange} onBlur={handleInputBlur} placeholder={t('your_age')} min="18" />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="weight">{t('weight')} (kg)</Label>
                            <Input id="weight" name="weight" type="number" value={formData.weight} onChange={handleInputChange} onBlur={handleInputBlur} placeholder={t('your_weight')} min="30" />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="height">{t('height')} (cm)</Label>
                            <Input id="height" name="height" type="number" value={formData.height} onChange={handleInputChange} onBlur={handleInputBlur} placeholder={t('your_height')} min="100" />
                        </div>
                    </>
                )
            case 2:
                return (
                    <RadioGroup value={formData.goal} onValueChange={(value) => setFormData(prev => ({ ...prev, goal: value }))}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="abnehmen" id="abnehmen" />
                            <Label htmlFor="abnehmen">{t('lose_weight')}</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="aufbauen" id="aufbauen" />
                            <Label htmlFor="aufbauen">{t('build_muscle')}</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="halten" id="halten" />
                            <Label htmlFor="halten">{t('maintain_weight')}</Label>
                        </div>
                    </RadioGroup>
                )
            case 3:
                return (
                    <>
                        <AnimatePresence>
                            {formData.trainingDays !== '0' && (
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{ duration: 0.3 }}
                                    className="space-y-2 overflow-hidden p-2"
                                >
                                    <Label htmlFor="trainingDays">{t('training_days_per_week')}</Label>
                                    <Input
                                        id="trainingDays"
                                        name="trainingDays"
                                        type="number"
                                        min="1"
                                        max="7"
                                        value={formData.trainingDays}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 7)) {
                                                handleInputChange(e);
                                            }
                                        }}
                                        placeholder={t('number_of_training_days')}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <div className="space-y-2">
                            <Label>{t('training_areas')}</Label>
                            <div className="space-y-2">
                                {[t('upper_body'), t('lower_body'), t('cardio')].map((area) => (
                                    <div key={area} className="flex items-center space-x-2">
                                        <Checkbox
                                            id={area.toLowerCase()}
                                            checked={formData.trainingAreas.includes(area)}
                                            onCheckedChange={() => handleCheckboxChange(area)}
                                            disabled={formData.trainingDays === '0'}
                                        />
                                        <Label htmlFor={area.toLowerCase()}>{area}</Label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex items-center space-x-2 mt-4">
                            <Checkbox
                                id="noWorkout"
                                checked={formData.trainingDays === '0'}
                                onCheckedChange={(checked) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        trainingDays: checked ? '0' : '',
                                        trainingAreas: checked ? [] : prev.trainingAreas
                                    }))
                                }}
                            />
                            <Label htmlFor="noWorkout">{t('no_workout')}</Label>
                        </div>
                    </>
                )
            case 4:
                return (
                  <div className="flex flex-col md:flex-row gap-8 rounded-lg shadow-md p-4">
                    <div className="space-y-2 md:w-4/10">
                      <p className="text-lg font-semibold">{t('overview_of_plan')}</p>
                      <ul className="list-disc list-inside space-y-2">
                        <li>{t('name')}: {formData.name}</li>
                        <li>{t('age')}: {formData.age} {t('years')}</li>
                        <li>{t('weight')}: {formData.weight} kg</li>
                        <li>{t('height')}: {formData.height} cm</li>
                        <li>{t('goal')}: {t(formData.goal)}</li>
                        <li>{t('training_days')}: {formData.trainingDays}</li>
                        <li>{t('training_areas_list')}: {formData.trainingAreas.map(area => t(area)).join(', ')}</li>
                      </ul>
                    </div>
                    <div className="w-full h-1/3 md:w-6/10 md:h-80 bg-white">
                      <ResponsiveLine
                        data={dummyData}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: 'Month',
                          legendOffset: 36,
                          legendPosition: 'middle'
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: 'Weight (kg)',
                          legendOffset: -40,
                          legendPosition: 'middle'
                        }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                          {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                  itemOpacity: 1
                                }
                              }
                            ]
                          }
                        ]}
                      />
                    </div>
                  </div>
                )
            case 5:
                return (
                    <>
                        <p>{t('choose_package')}</p>
                        <RadioGroup defaultValue="basic">
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="basic" id="basic" />
                                <Label htmlFor="basic">{t('basic_package')}</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="premium" id="premium" />
                                <Label htmlFor="premium">{t('premium_package')}</Label>
                            </div>
                        </RadioGroup>
                    </>
                )
            default:
                return null
        }
    }

    return (
        <div className="h-screen flex flex-col p-6 overflow-hidden relative">
            {showTutorial && <TutorialOverlay language={language} setShowTutorial={setShowTutorial} />}
            <div className="absolute top-4 right-4 flex items-center space-x-3">
                <InfoIcon onClick={() => setShowTutorial(true)} />
                <DarkModeToggle className="dark-mode-toggle" />
                <LanguageSwitcher className="language-switcher" currentLanguage={language} onLanguageChange={handleLanguageChange} />
            </div>
            <div className="flex-grow flex flex-col max-w-4xl mx-auto w-full">
                <h1 className="text-3xl font-bold text-center mb-4">AlgoFit</h1>
                <div className="mb-4 mt-4">
                    <div className="flex items-center">
                        {steps.map((step, index) => (
                            <React.Fragment key={step.number}>
                                <div className="flex flex-col items-center">
                                    <div
                                        className={`rounded-full flex items-center justify-center text-lg font-semibold transition-all duration-300
                                        ${currentStep === step.number ? 'w-16 h-16 text-xl' : 'w-12 h-12'}
                                        ${currentStep >= step.number ? 'bg-primary text-primary-foreground' : 'bg-muted text-muted-foreground'}`}
                                        data-step={step.number}
                                    >
                                        {step.number}
                                    </div>
                                    <span className={`mt-2 text-center transition-all duration-300 
                                        ${currentStep === step.number ? 'text-lg font-bold' : 'text-sm'}`}>
                                        {step.title}
                                    </span>
                                </div>
                                {index < steps.length - 1 && (
                                    <div className="flex-grow mb-8 h-2 bg-muted mx-2">
                                        <div
                                            className="h-full bg-primary transition-all duration-300 ease-in-out"
                                            style={{ width: `${currentStep > step.number ? '100%' : '0%'}` }}
                                        ></div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div className="flex-grow flex flex-col max-w-4xl mx-auto w-full">
                    <div className="justify-center items-center bg-card text-card-foreground rounded-lg p-3 flex flex-col">
                        <div className="flex-grow w-4/5 px-4 py-2 overflow-auto">
                            {renderStepContent(currentStep)}
                        </div>
                        <div className="flex justify-between w-4/5 mt-6 px-6">
                            <Button
                                onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 1))}
                                disabled={currentStep === 1}
                            >
                                {t('back')}
                            </Button>
                            <Button
                                onClick={() => setCurrentStep((prev) => Math.min(prev + 1, steps.length))}
                                disabled={currentStep === steps.length || !isStepValid(currentStep)}
                            >
                                {currentStep === steps.length ? t('buy_now') : t('next')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="absolute bottom-0 left-1/2 w-full max-w-md mx-auto">
                <AnimatePresence>
                    {alertInfo && (
                    <Alert
                        key={alertInfo.message}
                        message={alertInfo.message}
                        type={alertInfo.type}
                        onClose={handleCloseAlert}
                    />
                    )}
                </AnimatePresence>
            </div>
        </div>
    )
}
