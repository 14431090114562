import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface TutorialStep {
    element: string;
    title: string;
    description: string;
    position: { top: string; left: string };
  }

interface TutorialOverlayProps {    
    language: string;
    setShowTutorial: (show: boolean) => void;
}

const TutorialOverlay: React.FC<TutorialOverlayProps> = ({ language, setShowTutorial }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [translations, setTranslations] = useState<Record<string, string>>({})
    const [highlightPosition, setHighlightPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  
    useEffect(() => {
      const loadTranslations = async () => {
          const translationFile = await import(`@/localization/${language}.json`);
          setTranslations(translationFile.default);
      };
      loadTranslations();
    }, [language]);
  
    const t = useCallback((key: string) => {
      return translations[key] || key;
    }, [translations]);
  
    const tutorialSteps = useMemo(() => [
      {
        element: '[data-step="1"]',
        title: t('step1'),
        description: t('step1_description'),
        position: { top: '25%', left: '20%' },
      },
      {
        element: '[data-step="2"]',
        title: t('step2'),
        description: t('step2_description'),
        position: { top: '25%', left: '30%' },
      },
      {
        element: '[data-step="3"]',
        title: t('step3'),
        description: t('step3_description'),
        position: { top: '25%', left: '40%' },
      },
      {
        element: '[data-step="4"]',
        title: t('step4'),
        description: t('step4_description'),
        position: { top: '25%', left: '50%' },
      },
      {
        element: '.language-switcher',
        title: t('language_switcher'),
        description: t('language_switcher_description'),
        position: { top: '10%', left: '70%' },
      },
      {
        element: '.dark-mode-toggle',
        title: t('dark_mode_toggle'),
        description: t('dark_mode_toggle_description'),
        position: { top: '10%', left: '70%' },
      },
    ], [t]);

  useEffect(() => {
    const highlightElement = () => {
      const element = document.querySelector(tutorialSteps[currentStep].element);
      if (element) {
        const rect = element.getBoundingClientRect();
        setHighlightPosition({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
        });
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    if (isVisible) {
      highlightElement();
    }
  }, [currentStep, isVisible, tutorialSteps]);

  const handleNext = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setIsVisible(false);
      setShowTutorial(false);
    }
  };

  const handleSkip = () => {
    setIsVisible(false);
    setShowTutorial(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 pointer-events-none">
      <div className="absolute inset-0 bg-black bg-opacity-20 dark:bg-opacity-40">
        <div
          className="absolute bg-transparent"
          style={{
            top: `${highlightPosition.top}px`,
            left: `${highlightPosition.left}px`,
            width: `${highlightPosition.width}px`,
            height: `${highlightPosition.height}px`,
            boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
            borderRadius: tutorialSteps[currentStep].title.includes("Step") || tutorialSteps[currentStep].title.includes("Schritt") ? "50%" : ".6rem",
            transition: 'all 0.3s ease-in-out',
          }}
        />
      </div>
      <AnimatePresence>
        <motion.div
          key={currentStep}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="bg-card text-card-foreground rounded-lg p-4 shadow-lg absolute pointer-events-auto flex flex-col min-w-[350px]"
          style={tutorialSteps[currentStep].position}
        >
            <h3 className="text-lg font-bold mb-2 text-center">{tutorialSteps[currentStep].title}</h3>
            <p className="mb-4 text-center">{tutorialSteps[currentStep].description}</p>
            <div className="flex justify-between">
                <button
                    onClick={handleSkip}
                    className="px-4 py-2 bg-muted text-muted-foreground rounded"
                >
                    {t('skip')}
                </button>
                <button
                    onClick={handleNext}
                    className="px-4 py-2 bg-primary text-primary-foreground rounded"
                >
                    {currentStep === tutorialSteps.length - 1 ? t('finish') : t('next')}
                </button>
            </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default TutorialOverlay;