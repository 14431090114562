import React, { useState } from 'react';
import { Button } from './button';
import { ChevronDownIcon } from '@radix-ui/react-icons';

interface LanguageSwitcherProps {
  className?: string;
  currentLanguage: string;
  onLanguageChange: (language: string) => void;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ className, currentLanguage, onLanguageChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'en', flag: '🇬🇧' },
    { code: 'de', flag: '🇩🇪' },
  ];

  const currentLang = languages.find(lang => lang.code === currentLanguage) || languages[0];

  return (
    <div className={className}>
      <div className="relative">
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-between w-20 gap-2"
        >
          <span className="flex items-center gap-2">
            <span className="text-xl">{currentLang.flag}</span>
          </span>
          <ChevronDownIcon className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} />
        </Button>
        <div className={`absolute right-0 mt-2 w-20 bg-background dark:bg-gray-800 rounded-md shadow-lg overflow-hidden transition-all duration-300 ease-in-out origin-top ${isOpen ? 'max-h-40 scale-y-100' : 'max-h-0 scale-y-0'}`}>
          {languages.filter(lang => lang.code !== currentLanguage).map((lang) => (
            <button
              key={lang.code}
              className="w-full text-left px-4 py-2 hover:bg-accent hover:text-accent-foreground dark:hover:bg-gray-700 flex items-center gap-2"
              onClick={() => {
                onLanguageChange(lang.code);
                setIsOpen(false);
              }}
            >
              <span className="text-xl">{lang.flag}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;